<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Btn
          class="action-btn"
          icon="create"
          color="primary"
          text="Ajouter un contact"
          :to="{ name: 'supplierContactAdd', params: { fId: supplierId, action: 'add' } }"
        />
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{ name: 'suppliers' }"
        />
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
       <Container>
         <div class="grid">
           <div class="tmp-col">
             <KeyValue label="Prénom" :value="contact.prenom" />
             <KeyValue label="Nom" :value="contact.prenom" />
           </div>
           <div class="tmp-col">
             <KeyValue label="Téléphone portable" :value="contact.telephoneportable" />
             <KeyValue label="Email" :value="contact.email" />
           </div>
           <div class="tmp-col">
             <KeyValue label="Téléphone Fixe" :value="contact.telephonefixe" />
             <KeyValue label="Fonction" :value="contact.fonction" />
           </div>
           <div class="tpm-col">
             <KeyValue label="Téléphone Entreprise" :value="contact.telephoneentreprise" />
           </div>
           <KeyValue label="Remarques" :value="contact.remarques" />
         </div>
       </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'SupplierContactView',

  components: {
    Container,
    KeyValue,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      contact: [],
      supplierId: this.$route.params.fId,
      contactId: this.$route.params.cId,
    }
  },

  mounted() {
    this.getSupplierContact()
  },

  methods: {
    getSupplierContact() {
      this.fetchService.get(`fournisseur/${this.supplierId}/contact/${this.contactId}`)
        .then((response) => {
          this.contact = response.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
